import {CouplePaginate, CoupleType, PairedPersonType, PairingResponse, PersonPaginate, PersonType} from "../types/PersonType";
import {useAPI} from "../hooks/useAPI";
import {OptionType, ResponseErrorType} from "../types/CommonType";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../types/MedicalRecordType";
import { MedicationMetaType, TreatmentMedicationType, TreatmentResultType, TreatmentType } from "../types/TreatmentType";

export function TreatmentModel() {
    const {api} = useAPI();

    const create = async (treatment: TreatmentType, medical_record_id: number) => {
        return new Promise<ResponseErrorType | TreatmentType>((resolve) => {            
            api()
                .post(`/api/medical-record/${medical_record_id}/treatment`, treatment)
                .then((resp) => {
                    resolve(resp.data.treatment);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const update = async (treatment_id: number, treatment: TreatmentType) => {
        return new Promise<ResponseErrorType | TreatmentType>((resolve) => {            
            api()
                .put(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment_id}`, treatment)
                .then((resp) => {
                    resolve(resp.data.treatment);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const createEvolution = async (medical_record_id: number, medical_record_evolution: MedicalRecordEvolutionType) => {
        return new Promise<ResponseErrorType | MedicalRecordEvolutionType>((resolve) => {            
            api()
                .post(`/api/medical-record/${medical_record_id}/evolution`, medical_record_evolution)
                .then((resp) => {
                    resolve(resp.data.medical_record_evolution);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const createResult = async (result: TreatmentResultType, treatment: TreatmentType) => {
        return new Promise<ResponseErrorType | TreatmentResultType>((resolve) => {            
            api()
                .post(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment.id}/result`, result)
                .then((resp) => {
                    resolve(resp.data.result);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const updateResult = async (result: TreatmentResultType, treatment: TreatmentType) => {
        return new Promise<ResponseErrorType | TreatmentResultType>((resolve) => {            
            api()
                .put(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment.id}/result/${result.id}`, result)
                .then((resp) => {
                    resolve(resp.data.result);
                })
                .catch((err) => {
                    resolve({code: err.response.status, message: err.response.data.message, error: true})
                });
        });
    }

    const list = async (business_id: number | undefined = undefined, options: any | undefined = undefined, pageNumber: number=1, perPage: number=10) => {
        return new Promise<boolean | CouplePaginate>((resolve) => {
            api()
                .get(`/api/couple?business_id=${(business_id !== undefined) ? business_id : ""}&count=${perPage}&page=${pageNumber}&${new URLSearchParams(options).toString()}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }

    const get = async (medical_record_id:number, treatment_id: number) => {
        return new Promise<TreatmentType | boolean>((resolve) => {
            api()
                .get(`/api/medical-record/${medical_record_id}/treatment/${treatment_id}`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    }  
    
    const getResults = async (medical_record_id:number, treatment_id: number) => {
        return new Promise<TreatmentResultType[] | boolean>((resolve) => {
            api()
                .get(`/api/medical-record/${medical_record_id}/treatment/${treatment_id}/results`)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(() => resolve(false));
        });
    } 

    const remove = async (couple_id: number) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/couple/${couple_id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const removeResult = async (treatment: TreatmentType, treatment_result: TreatmentResultType) => {
        return new Promise<boolean>((resolve) => {
            api()
                .del(`/api/medical-record/${treatment.medical_record_id}/treatment/${treatment.id}/result/${treatment_result.id}`)
                .then((resp) => {
                    resolve(true);
                })
                .catch(() => resolve(false));
        });
    }

    const formatCoupleName = (couple: CoupleType) => {
        return `${couple.primary.name}` + (couple.secondary ? ` <> ${couple.secondary.name}`: '');
    }

    const getTreatmentTypeOptions = () : Array<OptionType> => {
        return [
            {label: "COITO PROGRAMADO", value: "COIP"},
            {label: "INSMEINAÇÃO INTRA-UTERINA", value: "INIU"},
            {label: "CRIO DE ÓVULOS", value: "CRIO"},
            {label: "FIV/ ICSI (TE A FRESCO)", value: "FIVF"},
            {label: "FIV/ICSI (FREEZE ALL)", value: "FIVA"},
            {label: "FIV/ICSI (PGT)", value: "FIVP"},
            {label: "FIV/ICSI (DOAÇÃO COMPARTILHADA)", value: "FIVC"},
            {label: "FIV/ICSI (ÓVULO DOADO / FREEZE ALL)", value: "FIVOA"},
            {label: "FIV/ICSI (ÓVULO DOADO / TE FRESCO - CICLO ARTIFICIAL)", value: "FIVFC"},
            {label: "FIV/ICSI (ÓVULO DOADO / TE FRESCO - CICLO NATURAL/MODIFCIADO)", value: "FIVFN"},
            {label: "TE (CICLO ARTIFICIAL)", value: "TEA"},
            {label: "TE (CICLO NATURAL/MODIFICADO)", value: "TEM"}
        ];
    }

    const getSemenOriginOptions = () : Array<OptionType> => {
        return [
            {label: "Não se aplica", value: "NSAP"},
            {label: "Ejaculado", value: "EJA"},
            {label: "PESA (PUNÇÃO EPIDIDIMO)", value: "PESA"},
            {label: "TESA (PUNÇÃO TESTICULAR)", value: "TESA"},
            {label: "MESA (EXPLORAÇÃO EPIDIDIMO)", value: "MESA"},
            {label: "TESE / mTESE (EXPLORAÇÃO TESTICULO)", value: "TESE"},
            {label: "SEMEN DOADOR ANÔNIMO", value: "SEDA"},
            {label: "SÊMEN DOADOR CONHECIDO", value: "SEDC"},
        ];
    }

    const getOvumOriginOptions = () : Array<OptionType> => {
        return [
            {"label": "Não se aplica", "value": "NSAP"},
            {label: "Óvulos próprios fresco", value: "OPRF"},
            {label: "Óvulos próprios congelado", value: "OPRC"},
            {label: "Óvulos doados fresco", value: "ODOF"},
            {label: "Óvulos doados congelado", value: "ODOC"}
        ];
    }

    const getMedicationsOptions = () : Array<OptionType> => {
        return [
            {label: "Rekovelle", value: "REKO", type: "TREAT", unit: 'mcg'},
            {label: "Menopur", value: "MENO", type: "TREAT", unit: 'UI'},
            {label: "Pergoveris", value: "PERG", type: "TREAT", unit: 'UI'},
            {label: "Merional", value: "MERI", type: "TREAT", unit: 'UI'},
            {label: "Elonva", value: "ELON", type: "TREAT", unit: 'mcg'},
            {label: "Fostimon", value: "FOST", type: "TREAT", unit: 'UI'},
            {label: "Duphaston", value: "DUPH", type: "TREAT", unit: 'mg'},
            {label: "Cerazette", value: "CERA", type: "TREAT", unit: 'comprimido(s)'},
            {label: "Gonapeptyl", value: "GONA", type: "TRIGG", unit: 'seringa(s)'},
            {label: "Ovidrel", value: "OVCI", type: "TRIGG", unit: 'cliques'},
            {label: "Ovidrel", value: "OVCA", type: "TRIGG", unit: 'caneta'},
            {label: "Choriomon", value: "CHOR", type: "TRIGG", unit: 'ampola(s)'},
            {label: "Cetrotide", value: "CETR", type: "TREAT", unit: 'seringa(s)'},
            {label: "Orgalutran", value: "ORGA", type: "TREAT", unit: 'seringa(s)'},
            {label: "Clexane", value: "CLEX", type: "TREAT", unit: 'mg'},
            {label: "GH", value: "GHMG", type: "TREAT", unit: 'mg'},
            {label: "GH", value: "GHUI", type: "TREAT", unit: 'UI'},
            {label: "Primolut NOR", value: "PRCO", type: "TREAT", unit: 'comprimido(s)'},
            {label: "Primolut NOR", value: "PRMG", type: "TREAT", unit: 'mg'},
            {label: "Letrozol", value: "LEMG", type: "TREAT", unit: 'mg'},
            {label: "Letrozol", value: "LECO", type: "TREAT", unit: 'comprimido(s)'},
            {label: "Primogyna", value: "PRMG", type: "TREAT", unit: 'mg'},
            {label: "Primogyna", value: "PRCO", type: "TREAT", unit: 'comprimido(s)'},
        ];
    }

    const getPosologyOptions = () : Array<OptionType> => {
        return [
            {label: "24/24 horas", value: "24"},
            {label: "12/12 horas", value: "12"},
            {label: "8/8 horas", value: "8"},
            {label: "6/6 horas", value: "6"},
            {label: "Dose única", value: "UNI"},
        ];
    }

    const getDailyDosage = ( meta: MedicationMetaType | undefined, posology: string) => {
        const posologyDaily = {
            "24": 1,
            "12": 2,
            "8": 3,
            "6": 4,
            "UNI": 1
        };
        let posologyKey = Object.keys(posologyDaily).indexOf(posology);        
        
        if(posologyKey > 0 && typeof meta?.value === "string" && meta.value !== "") {
            return Object.values(posologyDaily)[posologyKey] * parseInt(meta.value);
        }

        return meta?.value;
    }

    const getMedicationMeta = (id: string, meta: MedicationMetaType[]): MedicationMetaType | undefined => {
        for(let i in meta) {
            let medication = meta[i];
            if(medication.id === id) {
                return medication;
            }
        }
    }

    const groupMedications = (medications: TreatmentMedicationType[]) => {
        const isInMedicationList = (medication: TreatmentMedicationType, medications: TreatmentMedicationType[]) => {
            for(let i in medications) {
                if(medications[i].medication === medication.medication) {
                    return true;
                }
            }
            return false;
        }
        
        let uniqueMedications: TreatmentMedicationType[] = [];
        for(let i in medications) {
            if(!isInMedicationList(medications[i], uniqueMedications)) {
                uniqueMedications.push(medications[i]);
            }
        }

        return uniqueMedications;
    }

    return {
        create,
        update,
        remove,
        list,
        get,
        getResults,
        formatCoupleName,
        getTreatmentTypeOptions,
        getSemenOriginOptions,
        getOvumOriginOptions,
        createEvolution,
        getMedicationsOptions,
        getMedicationMeta,
        createResult,
        updateResult,
        removeResult,
        getPosologyOptions,
        getDailyDosage,
        groupMedications
    };
 }