import React, { FC, useEffect, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CoupleType, PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, OptionType, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { CoupleModel } from "../../../../models/CoupleModel";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../../models/MedicalRecordModel";
import { DefaultUser } from "../../../../providers/DefaultData";
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, BtnUndo, Editor, EditorProvider, Separator, Toolbar } from "react-simple-wysiwyg";
import { TreatmentType } from "../../../../types/TreatmentType";
import { TreatmentModel } from "../../../../models/TreatmentModel";
import CustomSelect from "../../../../components/form/CustomSelect";
import moment from "moment";
import { ExamFile, ExamOptionType, ExamResultType, ExamType } from "../../../../types/ExamType";
import { ExamModel } from "../../../../models/ExamModel";
import { FileUploadProvider, FileUploadProviderContext } from "../../../../providers/FileUploadProvider";
import FileUploader from "../../../../components/FileUploader";
import ExamFiles from "../ExamFiles";
import { DocumentModelType, DocumentType } from "../../../../types/DocumentType";
import { DocumentModel } from "../../../../models/DocumentModel";
import PDFModal from "../../../../components/PDFModal";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (document: DocumentType, print: boolean | number) => void
    medical_record: MedicalRecordType
    document?: DocumentType
}

const DocumentModal: FC<Props> = ({ show, onHide, onSave, medical_record, document }) => {
    const schema = yup.object().shape({
        type: yup.string().required(),
        name: yup.string().required(),
        content: yup.string().required(),
        model: yup.string()
    });
    const [formWasSubmitted, setFormWasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingModels, setLoadingModels] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);
    const [type, setType] = useState((document) ? document.type : '');
    const [models, setModels] = useState<DocumentModelType[]>([]);
    const [model, setModel] = useState<DocumentModelType | undefined>(undefined);



    const initialDocument: DocumentType = (document) ? document
        : {
            id: -1,
            type: "",
            medical_record_id: medical_record.id,
            content: "",
            name: ""
        };

    const onSubmit = async (values: DocumentType, print: boolean = false) => {
        setLoading(true);
        setFormWasSubmitted(false);
        setErrorMsg(DefaultErrorMsg);
        setHasError(false);

        // Create or update
        let resp: DocumentType | ResponseErrorType = (!document) ?
            await DocumentModel().create({
                ...values
            }, medical_record.id)
            : await DocumentModel().update(document.id, {
                ...values
            });
        setLoading(false);
        // Saving result
        if (!isResponseError(resp)) {
            const doc = resp as DocumentType;
            onSave(doc, (print) ? doc.id : false);
        } else {
            setHasError(true);
            setErrorMsg((resp as ResponseErrorType).message);
        }
    }

    useEffect(() => {
        setLoadingModels(true);
        setModel(undefined);
        DocumentModel()
            .getModels(type)
            .then(
                (models) => {
                    setLoadingModels(false);
                    if (typeof models !== 'boolean') {
                        setModels([...models]);
                    }
                },
                (err) => {
                    setLoadingModels(false);
                });
    }, [type]);

    return (
        <>

            <Modal
                show={show}
                onHide={() => {
                    onHide();
                    setFormWasSubmitted(false);
                }}
                className="modal-default"
                size="lg"
            >
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => {
                        onSubmit(values, false);
                    }}
                    initialValues={initialDocument}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        setFieldValue,
                        isValid,
                        errors }) => (
                        <Form noValidate onSubmit={(e) => {
                            setFormWasSubmitted(true);
                            handleSubmit(e);
                        }}>
                            <Modal.Header closeButton closeVariant="white">
                                <Modal.Title>
                                    Documento
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {(!isValid && formWasSubmitted) && (<Alert variant={'danger'}><FormattedMessage {...LANG_WARNING_FILL_ALL} /></Alert>)}
                                {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                                <Row>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Tipo</Form.Label>
                                            <CustomSelect
                                                options={DocumentModel().getDocumentTypes()}
                                                name="type"
                                                onChange={(o: OptionType) => {
                                                    setFieldValue('type', o.value);
                                                    setType(o.value);
                                                }}
                                                placeholder="Selecione..."
                                                value={values.type}
                                                isClearable={false}
                                                disabled={document !== undefined}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Modelo</Form.Label>
                                            <CustomSelect
                                                key={`models-${type}`}
                                                options={models.map((model) => {
                                                    return { 'label': model.name, 'value': model.id }
                                                })}
                                                name="model"
                                                onChange={(o: OptionType) => {
                                                    for (let i in models) {
                                                        if (models[i].id === o.value) {
                                                            setFieldValue('content', models[i].model);
                                                            setFieldValue('name', models[i].name);
                                                            setModel(models[i]);
                                                        }
                                                    }
                                                }}
                                                placeholder={loadingModels ? 'Carregando...' : "Selecione..."}
                                                isClearable={false}
                                                disabled={type === '' || loadingModels || document !== undefined}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    {(model || document) && (
                                        <>
                                            <Col lg={12}>
                                                <div className="title-divider">
                                                    <h6>DOCUMENTO</h6>
                                                    <hr />
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Nome</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        placeholder="Nome ou descrição"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3" controlId="name">
                                                    <Form.Label>Documento</Form.Label>
                                                    <EditorProvider>
                                                        <Editor
                                                            name="content"
                                                            placeholder={'Documento'}
                                                            value={values.content}
                                                            onChange={(e) => {
                                                                setFieldValue('content', e.target.value);
                                                            }}
                                                        >
                                                            <Toolbar>
                                                                <BtnBold />
                                                                <BtnItalic />
                                                                <BtnUnderline />
                                                                <BtnStrikeThrough />
                                                                <Separator />
                                                                <BtnNumberedList />
                                                                <BtnBulletList />
                                                            </Toolbar>
                                                        </Editor>
                                                    </EditorProvider>
                                                </Form.Group>
                                            </Col>
                                        </>
                                    )}
                                </Row>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light" disabled={loading}>
                                    Fechar
                                </Button>
                                <Button
                                    variant="outline-light"
                                    type={"button"}
                                    disabled={loading}
                                    onClick={() => {
                                        onSubmit(values, true);
                                    }}
                                    className="btn-custom btn-custom-light"
                                >
                                    {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> :
                                        <span><i className="bi bi-printer" /> Salvar e  Imprimir</span>}
                                </Button>
                                <Button variant="primary" type={"submit"} disabled={loading} className="btn-custom bg-custom-gradient">
                                    {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> :
                                        <span>Salvar</span>}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal >
        </>
    )
}

export default DocumentModal;