import React, { FC, useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../providers/ImageUploadProvider";
import ImageUploader from "../../../components/ImageUploader";
import clsx from "clsx";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_DELETE_CONFIRM, LANG_COMMON_DELETE_ERROR, LANG_COMMON_DELETE_SUCCESS, LANG_COMMON_LOADING, LANG_COMMON_VIEW, LANG_PERSON_AGE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_SKIN_COLOR } from "../../../lang";
import { OptionsContext } from "../../../providers/OptionsContextProvider";
import { PersonType } from "../../../types/PersonType";
import { useUtil } from "../../../hooks/useUtil";
import "../treatment-table.css";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../models/MedicalRecordModel";
import { PersonModel } from "../../../models/PersonModel";
import { TreatmentMedicationType, TreatmentResultType, TreatmentType } from "../../../types/TreatmentType";
import CustomSelect from "../../../components/form/CustomSelect";
import TreatmentAddModal from "./modal/TreatmentAddModal";
import TreatmentResultAddModal from "./modal/TreatmentResultAddModal";
import { TreatmentModel } from "../../../models/TreatmentModel";
import { OptionType } from "../../../types/CommonType";
import { TreatmentContext, TreatmentContextProvider } from "../../../providers/TreatmentContextProvider";
import Loader from "../../../components/messages/Loader";
import LoaderInside from "../../../components/messages/LoaderInside";
import Confirm from "../../../components/messages/Confirm";
import { toast } from "react-toastify";
import { FileUploadProvider, FileUploadProviderContext } from "../../../providers/FileUploadProvider";
import { ClipLoader } from "react-spinners";
import PDFModal from "../../../components/PDFModal";
import PrescriptionModal from "./modal/PrescriptionModal";

interface Props {
    active_treatment: TreatmentType | null
    medical_record: MedicalRecordType
}

const MedicalRecordTreatmentTab: FC<Props> = ({ active_treatment, medical_record }) => {
    const [showModal, setShowModal] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
    const [treatments, setTreatments] = useState(medical_record.treatments);
    const [treatmentToEdit, setTreatmentToEdit] = useState<TreatmentType | null>(null);
    const [resultToEdit, setResultToEdit] = useState<TreatmentResultType | null>(null);
    const [selectedDate, setSelectedDate] = useState<string>(moment(new Date()).format("YYYY-MM-DD"))
    const [resultToDelete, setResultToDelete] = useState<TreatmentResultType | null>(null);

    const [printUrl, setPrintUrl] = useState("");
    const [showPrint, setShowPrint] = useState(false);

    const intl = useIntl();
    const { getOption } = useUtil();

    const calculateDaysOfStart = (treatment: TreatmentType, result: TreatmentResultType) => {
        let diff = (moment(result.date).diff(moment(treatment?.eoc_date, 'YYYY-MM-DD')) / (60 * 60 * 24 * 1000));

        return (diff + 1).toFixed(0);
    }

    const calculateDaysOfCycle = (treatment: TreatmentType, result: TreatmentResultType) => {
        let diff = (moment(result.date).diff(moment(treatment?.dum_date, 'YYYY-MM-DD')) / (60 * 60 * 24 * 1000));

        return (diff + 1).toFixed(0);
    }

    const selectResult = (date: string, results: TreatmentResultType[]) => {
        console.log(date)
        for (let i in results) {
            if (results[i].date === date) {
                setResultToEdit(results[i]);
                setSelectedDate(results[i].date);
                setShowResultModal(false);
                return;
            }
        }

        setResultToEdit(null);
        setSelectedDate(date);
        setShowResultModal(true);
    }

    return (
        <TreatmentContextProvider treatment={active_treatment}>
            <TreatmentContext.Consumer>
                {({ loadResults, loading, treatment, setTreatment, loadingResults, results }) => (
                    <>
                        {showModal && (
                            <TreatmentAddModal
                                show={showModal}
                                onHide={() => {
                                    setShowModal(false);
                                }}
                                onSave={(treatment) => {
                                    setTreatments([...treatments, treatment]);
                                    setTreatment(treatment);
                                    setShowModal(false);
                                }}
                                treatment={null}
                                medical_record={medical_record}
                            />
                        )}
                        {printUrl && showPrint && (
                            <PDFModal
                                url={printUrl}
                                show={true}
                                onHide={() => setShowPrint(false)}
                            />
                        )}
                        {treatmentToEdit && (
                            <TreatmentAddModal
                                show={true}
                                onHide={() => {
                                    setTreatmentToEdit(null);
                                }}
                                onSave={(treatment) => {
                                    let updatedTreatments = treatments;
                                    for (let i in treatments) {
                                        if (treatment.id === treatments[i].id) {
                                            updatedTreatments[i] = treatment;
                                            setTreatments(updatedTreatments);
                                        }
                                    }
                                    setTreatment(treatment);
                                    setTreatmentToEdit(null);
                                }}
                                treatment={treatmentToEdit}
                                medical_record={medical_record}
                            />
                        )}
                        {showPrescriptionModal && treatment && (
                            <PrescriptionModal
                                show={true}
                                onHide={() => {
                                    setShowPrescriptionModal(false);
                                }}
                                onSave={(treatment) => {
                                    setShowPrescriptionModal(false);
                                }}
                                treatment={treatment}
                                medical_record={medical_record}
                            />
                        )}
                        {treatment && showResultModal && (
                            <TreatmentResultAddModal
                                show={showResultModal}
                                onHide={() => setShowResultModal(false)}
                                onSave={(result) => {
                                    setShowResultModal(false);
                                    loadResults(treatment.medical_record_id, treatment.id);
                                }}
                                treatment={treatment}
                                onDateChange={(date) => selectResult(date, results)}
                                result={null}
                                key={`${'add'}`}
                                date={selectedDate}
                            />
                        )}
                        {treatment && resultToEdit && (
                            <TreatmentResultAddModal
                                show={true}
                                onHide={() => setResultToEdit(null)}
                                onSave={(result) => {
                                    setResultToEdit(null);
                                    loadResults(treatment.medical_record_id, treatment.id);
                                }}
                                treatment={treatment}
                                result={resultToEdit}
                                onDateChange={(date) => selectResult(date, results)}
                                key={`${resultToEdit.id}`}
                                date={resultToEdit.date}
                            />
                        )}
                        {resultToDelete && (
                            <Confirm
                                message={intl.formatMessage(LANG_COMMON_DELETE_CONFIRM)}
                                show={true}
                                onConfirm={async () => {
                                    setResultToDelete(null);
                                    const id = toast.loading(intl.formatMessage(LANG_COMMON_LOADING));
                                    let result = false;
                                    if (resultToDelete !== undefined && treatment) {
                                        result = await TreatmentModel().removeResult(treatment, resultToDelete);
                                    } else {
                                        return;
                                    }

                                    toast.dismiss(id);
                                    if (result) {
                                        toast.success(intl.formatMessage(LANG_COMMON_DELETE_SUCCESS));
                                        loadResults(treatment.medical_record_id, treatment.id);
                                    } else {
                                        toast.error(`${intl.formatMessage(LANG_COMMON_DELETE_ERROR)}!`, {
                                            hideProgressBar: true,
                                            autoClose: false
                                        });
                                    }
                                }}
                                onHide={() => setResultToDelete(null)}
                            />
                        )}
                        <Row>
                            {treatment && (
                                <>
                                    <Col lg={{ span: 3 }} style={{ zIndex: 999 }}>
                                        <Form.Label>Tratamento</Form.Label>
                                        <CustomSelect
                                            name="treatment"
                                            key={`treatment-${treatment.id}-${treatment.type}`}
                                            onChange={(o: OptionType) => {
                                                for (let i in treatments) {
                                                    if (o.value === treatments[i].id) {
                                                        setTreatment(treatments[i]);
                                                    }
                                                }
                                            }}
                                            value={treatment.id}
                                            options={treatments.map((item) => {
                                                return {
                                                    'label': `${getOption(item.type, TreatmentModel().getTreatmentTypeOptions())?.label} - ${moment(item.eoc_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
                                                    'value': item.id
                                                };
                                            })}
                                            isClearable={false}
                                        />
                                    </Col>

                                    <Col lg={{ span: 4 }} className="mt-4">
                                        <a href="" className="btn btn-light" onClick={(e) => {
                                            e.preventDefault();
                                            setTreatmentToEdit(treatment);
                                        }}>
                                            <i className="bi bi-pencil-fill"></i> Editar
                                        </a>
                                        <FileUploadProvider url={undefined} onChange={() => { }} onDone={() => { }}>
                                            <FileUploadProviderContext.Consumer>
                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, print, uploadErrorMessage }) => (
                                                    <a href="" className="btn btn-light ms-2" onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowPrint(false);
                                                        setPrintUrl("");
                                                        print(`/api/treatment/${treatment.id}`).then((resp) => {
                                                            if (typeof resp === 'string') {
                                                                setPrintUrl(resp);
                                                                setShowPrint(true);
                                                            }
                                                        });
                                                    }}>
                                                        {loading && (
                                                            <ClipLoader color={'#000'} loading={loading} size={'1rem'} />
                                                        )}
                                                        {!loading && (<><i className="bi bi-printer-fill"></i> Imprimir</>)}
                                                    </a>
                                                )}
                                            </FileUploadProviderContext.Consumer>
                                        </FileUploadProvider>
                                        <a href="" className="btn btn-light mt-2" onClick={(e) => {
                                            e.preventDefault();
                                            setShowPrescriptionModal(true);
                                        }}>
                                            {loading && (
                                                <ClipLoader color={'#000'} loading={loading} size={'1rem'} />
                                            )}
                                            {!loading && (<><i className="bi bi-file-medical-fill"></i> Receita</>)}
                                        </a>
                                        <a href="" className="btn btn-light ms-2 mt-2" onClick={(e) => {
                                            e.preventDefault();
                                            selectResult(moment().format("YYYY-MM-DD"), results);
                                        }}>
                                            <i className="bi bi-plus-square-fill"></i> Inserir dados
                                        </a>
                                    </Col>
                                </>
                            )}
                            <Col lg={{ span: 3, offset: (treatment) ? 2 : 9 }}>
                                <Button
                                    className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100 btn btn-primary mt-4"
                                    onClick={() => setShowModal(true)}
                                >NOVO TRATAMENTO</Button>
                            </Col>
                            <Col lg={12}>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                {loadingResults && (
                                    <div className="loading-container">
                                        <LoaderInside />
                                    </div>
                                )}
                                {!loadingResults && treatment && treatment?.medications && (
                                    <div className="table-container">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Data</th>
                                                    <th>Dia do Ciclo</th>
                                                    <th>Dias EOC</th>
                                                    {TreatmentModel().groupMedications(treatment?.medications).map((item, index) => (
                                                        <th>{getOption(item.medication, TreatmentModel().getMedicationsOptions().map((o) => { return { ...o, label: `${o.label} (${o.unit})` } }))?.label}</th>
                                                    ))}
                                                    <th>End (mm)</th>
                                                    <th>Óvario Direito</th>
                                                    <th>Óvario Esq</th>
                                                    <th>Total (&gt;14)</th>
                                                    <th>E2</th>
                                                    <th>LH</th>
                                                    <th>Outros Exames</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {results.map((result, index) => (
                                                    <tr className={`${result.right_ovary !== null ? 'has-ultra' : ''}`}>
                                                        <td>
                                                            {moment(result.date).format("DD/MM/YYYY")}
                                                            {" "}
                                                            <a href="" onClick={(e) => {
                                                                e.preventDefault();
                                                                setResultToEdit(result);
                                                            }}>
                                                                <i className="bi bi-pencil-fill text-secondary"></i>
                                                            </a>
                                                            {" "}
                                                            <a href="" onClick={(e) => {
                                                                e.preventDefault();
                                                                setResultToDelete(result);
                                                            }}>
                                                                <i className="bi bi-trash-fill text-danger"></i>
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {calculateDaysOfCycle(treatment, result)}°
                                                        </td>
                                                        <td>
                                                            {calculateDaysOfStart(treatment, result)}
                                                        </td>
                                                        {treatment?.medications && TreatmentModel().groupMedications(treatment?.medications).map((item, index) => (
                                                            <td>{(result.medications) ?
                                                                TreatmentModel().getDailyDosage(
                                                                    TreatmentModel().getMedicationMeta(item.medication, JSON.parse(result.medications)),
                                                                    item.posology
                                                                )
                                                                : ""}</td>
                                                        ))}
                                                        <td>{result.endometrium}</td>
                                                        <td>{result.right_ovary}</td>
                                                        <td>{result.left_ovary}</td>
                                                        <td>{result.total_gt_14}</td>
                                                        <td>{result.e2}</td>
                                                        <td>{result.lh}</td>
                                                        <td>{result.others}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </TreatmentContext.Consumer>
        </TreatmentContextProvider>

    );
}

export default MedicalRecordTreatmentTab;