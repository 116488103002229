import React, { FC, useEffect, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col, Table } from "react-bootstrap";
import { isResponseError, OptionType, ResponseErrorType } from "../../../../types/CommonType";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import { TreatmentMedicationType, TreatmentType } from "../../../../types/TreatmentType";
import { TreatmentModel } from "../../../../models/TreatmentModel";
import CustomSelect from "../../../../components/form/CustomSelect";
import InputMask from 'react-input-mask';

interface Props {
    onChange: (medications: TreatmentMedicationType[]) => void
    medications: TreatmentMedicationType[]
    treatment: TreatmentType | null
    type: string
    prescription?: boolean
}

const TreatmentMedications: FC<Props> = ({ onChange, medications, treatment, type, prescription = false }) => {
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [data, setData] = useState<TreatmentMedicationType[]>(medications.map((m) => {return {...m, print: true}}));
    const [selectAll, setSelectAll] = useState(true);    

    useEffect(() => {
        onChange(data);
    }, [data]);

    const updatePrint = (index: number, print: boolean) => {
        const newData = data;
        newData[index].print = print;
        setData([...newData]);
    }

    return (
        <Row>
            <Col lg={12}>
                <div className="title-divider">
                    <h6>{type === 'TREAT'
                        ? 'Estímulo Ovariano Controlado (Medicações)'
                        : 'Trigger (Medicações)'
                    }</h6>
                    <hr />
                </div>
            </Col>

            <Col lg={12}>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Medicação</th>
                            <th>Dosagem</th>
                            <th>Posologia</th>
                            <th>Data início</th>
                            <th>Data fim</th>
                            <th>Horário</th>
                            {!prescription && (<th>Options</th>)}
                            {prescription && (<th><Form.Check type="checkbox" onChange={() => {
                                for(let i in data) {
                                    updatePrint(parseInt(i), !selectAll);
                                }
                                setSelectAll(!selectAll);
                            }} checked={selectAll}></Form.Check></th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <>
                                <tr key={item.id}>
                                    <td>
                                        <Form.Group className="mb-3" controlId="name">
                                            <CustomSelect
                                                options={TreatmentModel().getMedicationsOptions().filter((o) => o.type === type).map((o) => { return { ...o, label: `${o.label} (${o.unit})` } })}
                                                key={`medication-${item.medication}`}
                                                name={`medication-${item.medication}`}
                                                onChange={(o: OptionType) => {
                                                    data[index].medication = o.value;
                                                    setData([...data]);
                                                }}
                                                placeholder="Selecione..."
                                                value={item.medication}
                                                isSearchable={true}
                                                disabled={prescription}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Control
                                                type="number"
                                                value={item.dosage}
                                                onChange={(e) => {
                                                    data[index].dosage = e.target.value;
                                                    setData([...data]);
                                                }}
                                                disabled={prescription}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="name">
                                            <CustomSelect
                                                options={TreatmentModel().getPosologyOptions()}
                                                name={`posology`}
                                                onChange={(o: OptionType) => {
                                                    data[index].posology = o.value;
                                                    setData([...data]);
                                                }}
                                                placeholder="Selecione..."
                                                value={item.posology}
                                                disabled={prescription}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Control
                                                type="date"
                                                value={item.date_start}
                                                onChange={(e) => {
                                                    data[index].date_start = e.target.value;
                                                    setData([...data]);
                                                }}
                                                disabled={prescription}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Control
                                                type="date"
                                                value={item.date_end}
                                                onChange={(e) => {
                                                    data[index].date_end = e.target.value;
                                                    setData([...data]);
                                                }}
                                                disabled={prescription}
                                            />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group className="mb-3" controlId="name">
                                            <InputMask
                                                value={item.start_time}
                                                onChange={(e) => {
                                                    data[index].start_time = e.target.value;
                                                    setData([...data]);
                                                }}
                                                name={"start_time"}
                                                type="text"
                                                mask="99:99"
                                                className="form-control"
                                                disabled={prescription}
                                            />
                                        </Form.Group>
                                    </td>
                                    {!prescription && (
                                        <td>
                                            <div>
                                                <a href="#" className="btn btn-light" onClick={(e) => {
                                                    data.splice(index, 1);
                                                    setData([...data]);
                                                }}>
                                                    <i className="bi bi-trash"></i>
                                                </a>
                                            </div>
                                        </td>
                                    )}
                                    {prescription && (
                                        <td>
                                            <Form.Check type="checkbox" onChange={() => {
                                                updatePrint(index, !item.print);
                                            }} checked={item.print}></Form.Check>
                                        </td>
                                    )}
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </Col>



            {!prescription && (
                <Col lg={12}>
                <Form.Group className="mb-3" controlId="name">
                    <Button variant="primary" className="btn-custom bg-custom-gradient"
                        onClick={() => {
                            setData([...data, {
                                id: -1,
                                medication: '',
                                treatment_id: (treatment) ? treatment.id : -1,
                                date_start: '',
                                date_end: '',
                                start_time: '',
                                dosage: 0,
                                posology: "",
                                type: type
                            }]);
                        }}
                    >
                        Adicionar medicação
                    </Button>
                </Form.Group>
            </Col>
            )}
        </Row>
    )
}

export default TreatmentMedications;