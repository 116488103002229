import React, { FC, useContext, useEffect, useState } from "react";
import { Accordion, Badge, Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../providers/ImageUploadProvider";
import ImageUploader from "../../../components/ImageUploader";
import clsx from "clsx";
import { useAuth } from "../../../hooks/useAuth";
import moment from "moment";
import { useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_DELETE_CONFIRM, LANG_COMMON_DELETE_ERROR, LANG_COMMON_DELETE_SUCCESS, LANG_COMMON_LOADING, LANG_COMMON_VIEW, LANG_PERSON_AGE, LANG_PERSON_EYES_COLOR, LANG_PERSON_HAIR_COLOR, LANG_PERSON_HAIR_TYPE, LANG_PERSON_HEIGHT, LANG_PERSON_RACE, LANG_PERSON_SKIN_COLOR } from "../../../lang";
import { OptionsContext } from "../../../providers/OptionsContextProvider";
import { PersonType } from "../../../types/PersonType";
import { useUtil } from "../../../hooks/useUtil";
import "../treatment-table.css";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../models/MedicalRecordModel";
import { PersonModel } from "../../../models/PersonModel";
import { TreatmentResultType, TreatmentType } from "../../../types/TreatmentType";
import CustomSelect from "../../../components/form/CustomSelect";
import TreatmentAddModal from "./modal/TreatmentAddModal";
import TreatmentResultAddModal from "./modal/TreatmentResultAddModal";
import { TreatmentModel } from "../../../models/TreatmentModel";
import { OptionType, isResponseError } from "../../../types/CommonType";
import { TreatmentContext, TreatmentContextProvider } from "../../../providers/TreatmentContextProvider";
import Loader from "../../../components/messages/Loader";
import LoaderInside from "../../../components/messages/LoaderInside";
import Confirm from "../../../components/messages/Confirm";
import { toast } from "react-toastify";
import { ExamContext, ExamContextProvider } from "../../../providers/ExamContextProvider";
import ExamModal from "./modal/ExamModal";
import { ExamModel } from "../../../models/ExamModel";
import { ExamType } from "../../../types/ExamType";
import Error from "../../../components/messages/Error";
import ExamFiles from "./ExamFiles";
import { DocumentContext, DocumentContextProvider } from "../../../providers/DocumentContextProvider";
import { DocumentModel } from "../../../models/DocumentModel";
import DocumentModal from "./modal/DocumentModal";
import { FileUploadProvider, FileUploadProviderContext } from "../../../providers/FileUploadProvider";
import FileUploader from "../../../components/FileUploader";
import { DocumentType } from "../../../types/DocumentType";
import PDFModal from "../../../components/PDFModal";

interface Props {
    medical_record: MedicalRecordType
}

const MedicalRecordDocumentTab: FC<Props> = ({ medical_record }) => {
    const [showModal, setShowModal] = useState(false);
    const [documentToEdit, setDocumentToEdit] = useState<DocumentType | undefined>(undefined);
    const [errorMsg, setErrorMsg] = useState<string | undefined>("");
    const [loadingExam, setLoadingExam] = useState(false);
    const [printUrl, setPrintUrl] = useState("");
    const [showPrint, setShowPrint] = useState(false);

    return (
        <DocumentContextProvider medical_record_id={medical_record.id}>
            <DocumentContext.Consumer>
                {({ loading, documents, loadDocuments }) => (
                    <>
                        {loadingExam && (
                            <Loader
                                fullscreen={true}
                            />
                        )}
                        {printUrl && showPrint && (
                            <PDFModal
                                url={printUrl}
                                show={true}
                                onHide={() => setShowPrint(false)}
                            />
                        )}
                        <FileUploadProvider url={undefined} onChange={() => { }} onDone={() => { }}>
                            <FileUploadProviderContext.Consumer>
                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, print, uploadErrorMessage }) => (
                                    <>
                                        {showModal && (
                                            <DocumentModal
                                                show={true}
                                                onHide={() => {
                                                    setShowModal(false);
                                                }}
                                                onSave={(treatment, printDocument) => {
                                                    setShowModal(false);
                                                    loadDocuments(medical_record.id);

                                                    if (printDocument) {
                                                        setShowPrint(false);
                                                        setPrintUrl("");
                                                        print(`/api/medical-record/${medical_record.id}/document/${printDocument}?print=Y`).then((resp) => {
                                                            if (typeof resp === 'string') {
                                                                setPrintUrl(resp);
                                                                setShowPrint(true);
                                                            }
                                                        });
                                                    }
                                                }}
                                                medical_record={medical_record}
                                            />
                                        )}
                                        {documentToEdit && (
                                            <DocumentModal
                                                show={true}
                                                onHide={() => {
                                                    setDocumentToEdit(undefined);
                                                }}
                                                onSave={(treatment, printDocument) => {
                                                    setDocumentToEdit(undefined);
                                                    loadDocuments(medical_record.id);

                                                    if (printDocument) {
                                                        setShowPrint(false);
                                                        setPrintUrl("");
                                                        print(`/api/medical-record/${medical_record.id}/document/${printDocument}?print=Y`).then((resp) => {
                                                            if (typeof resp === 'string') {
                                                                setPrintUrl(resp);
                                                                setShowPrint(true);
                                                            }
                                                        });
                                                    }
                                                }}
                                                medical_record={medical_record}
                                                document={documentToEdit}
                                            />
                                        )}
                                    </>
                                )}
                            </FileUploadProviderContext.Consumer>
                        </FileUploadProvider>

                        {errorMsg && (
                            <Error
                                message={errorMsg}
                                show={true}
                                onHide={() => setErrorMsg(undefined)}
                            />
                        )}
                        <Row>
                            <Col lg={{ span: 3, offset: 9 }}>
                                <Button
                                    className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100 btn btn-primary mb-2"
                                    onClick={() => setShowModal(true)}
                                >Novo documento</Button>
                            </Col>
                            <Col lg={12}>
                                <hr />
                            </Col>
                            <Col lg={12}>
                                {loading && (
                                    <LoaderInside />
                                )}
                                {!loading && (
                                    <Accordion>
                                        {DocumentModel().getDocumentTypes().map((documentType, indexType) => (
                                            <Accordion.Item eventKey={`type-${indexType}`}>
                                                <Accordion.Header>{documentType.label}</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        {documents.map((document) => {
                                                            if (document.type === documentType.value) {
                                                                return (
                                                                    <Col lg={12}>
                                                                        <FileUploadProvider url={`/api/medical-record/${document.medical_record_id}/document/${document.id}?print=Y`} onChange={() => loadDocuments(medical_record.id)} key={`document-${document.id}`}>
                                                                            <FileUploadProviderContext.Consumer>
                                                                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, print, uploadErrorMessage }) => (
                                                                                    <FileUploader
                                                                                        key={`document-${document.id}`}
                                                                                        hasUploadError={hasUploadError}
                                                                                        hasDownloadError={false}
                                                                                        uploadErrorMessage={uploadErrorMessage}
                                                                                        download={download}
                                                                                        loading={loading}
                                                                                        url={url}
                                                                                        edit={true}
                                                                                        print={print}
                                                                                        onEditClick={() => setDocumentToEdit(document)}
                                                                                        onNameClick={() => setDocumentToEdit(document)}
                                                                                        onChange={onChange}
                                                                                        onRemove={onRemove}
                                                                                        uploadUrl={``}
                                                                                        removeUrl={`/api/medical-record/${document.medical_record_id}/document/${document.id}`}
                                                                                        remove={true}
                                                                                        name={`${document.name} - ${moment(document.created_at).format("DD/MM/YYYY à[s] HH:mm")}`}
                                                                                    />
                                                                                )}
                                                                            </FileUploadProviderContext.Consumer>
                                                                        </FileUploadProvider>
                                                                    </Col>
                                                                )
                                                            }
                                                        })}
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </DocumentContext.Consumer>
        </DocumentContextProvider >

    );
}

export default MedicalRecordDocumentTab;