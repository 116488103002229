import React, { FC, useEffect, useRef, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { LANG_COMMON_CANCEL } from "../lang";

interface Props {
    show: boolean
    onHide: () => void
    url: string
}

const PDFModal: FC<Props> = ({ show, onHide, url }) => {
    const [pdfUrl, setPdfUrl] = useState<string>(url);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const handlePrint = () => {
        if (iframeRef.current) {
            iframeRef.current.contentWindow?.focus();
            iframeRef.current.contentWindow?.print();
        }
    };

    return (
        <>
            <div style={{
                position: "absolute",
                top: '-99999px'
            }}>
                <iframe
                    ref={iframeRef}
                    src={pdfUrl}
                    style={{ flexGrow: 1, border: "none" }}
                    onLoad={handlePrint}
                ></iframe>
            </div>
        </>
    )
}

export default PDFModal;