import axios, {AxiosResponse} from "axios";
import {useAuth} from "./useAuth";
import {useConfig} from "./useConfig";
import { getLocale } from "../util";

export function useAPI() {
    const locale = () => {
        return getLocale() || "pt";
    }
    const {getConfig} = useConfig();
    const API_URL = getConfig("API_URL");
    const {getToken} = useAuth();
    const timeout = 60000;
    const {deleteToken} = useAuth();

    const modifiedAxios = axios.create();
    // Add a response interceptor
    modifiedAxios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if(error.response.status === 401){
            deleteToken();
            window.location.reload();
        }

        return Promise.reject(error);
    });

    const api = () => {
        const get = (path: string) => {
            return modifiedAxios.get(`${API_URL}${path}`, {
                headers: {
                    "Authorization": "Bearer " + getToken(),
                    "Locale": locale()
                },
                timeout: timeout
            });
        };

        const post = (path: string, data: any) => {
            return modifiedAxios.post(`${API_URL}${path}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + getToken(),
                    "Locale": locale()
                },
                timeout: timeout
            });
        };

        const put = (path: string, data: any) => {
            return modifiedAxios.put(`${API_URL}${path}`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + getToken(),
                    "Locale": locale()
                },
                timeout: timeout
            });
        };

        const del = (path: string) => {
            return modifiedAxios.delete(`${API_URL}${path}`, {
                headers: {
                    "Authorization": "Bearer " + getToken(),
                    "Locale": locale()
                },
                timeout: timeout
            });
        };

        const upload = (path: string, file: any) => {
            let data = new FormData();
            data.append('file', file);

            return modifiedAxios.post(`${API_URL}${path}`, data, {
                headers: {
                    "Content-Type": "multipart/formdata",
                    "Authorization": "Bearer " + getToken(),
                    "Locale": locale()
                },
                timeout: timeout
            });
        };
        
        const download = (path: string) => {
            return new Promise<ArrayBuffer | null | string | number>((resolve) => {
                modifiedAxios.get(`${API_URL}${path}`, {
                    responseType:"blob",
                    headers: {
                        "Authorization": "Bearer " + getToken(),
                        "Locale": locale()
                    },
                })
                    .then(function (response) {

                        let reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function() {
                            resolve(reader.result);
                        }
                    })
                    .catch((err) => {
                        resolve(err.response.status);
                    })
            });
        }

        const downloadPDF = (path: string, params: any={}) => {
            return new Promise<AxiosResponse>((resolve) => {
                modifiedAxios.get(`${API_URL}${path}`, {
                    responseType:"blob",
                    headers: {
                        "Authorization": "Bearer " + getToken(),
                        "Locale": locale()
                    },
                    params
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch((err) => {
                        resolve(err.response);
                    });
            });
        }

        const downloadAndPrintPDF = (path: string, params: any={}) => {
            return new Promise(async (resolve) => {
                try {
                    const response = await downloadPDF(path, params);
                    
                    if (response.status !== 200) {
                        throw new Error("Failed to download PDF");
                    }
            
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const blobUrl = URL.createObjectURL(blob);
                    
                    resolve(blobUrl);                    
                } catch (error) {
                    resolve(false);
                }
            });
        };

        return {
            get,
            post,
            put,
            del,
            upload,
            download,
            downloadPDF,
            downloadAndPrintPDF
        };
    };

    const doLogin = (email: string, password: string, extra: any = {}) => {
        return api().post("/api/auth",
            {...extra,
                email: email,
                password: password
            });
    };

    const doLogout = () => {
        return api().del("/api/auth");
    };

    return {
        doLogout,
        doLogin,
        api
    };
}
