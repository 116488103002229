import React, { FC, useState } from "react";
import { Form, Modal, Button, Alert, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { CoupleType, PersonType } from "../../../../types/PersonType";
import { PersonModel } from "../../../../models/PersonModel";
import { isResponseError, OptionType, ResponseErrorType } from "../../../../types/CommonType";
import TranslateTerms from "../../../../components/TranslateTerms";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CANCEL, LANG_COMMON_DIAGNOSIS, LANG_COMMON_EVOLUTION, LANG_COMMON_LEGAL_ID, LANG_COMMON_LOADING, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NEW, LANG_COMMON_PATIENT, LANG_COMMON_SAVE, LANG_ERROR, LANG_WARNING_FILL_ALL } from "../../../../lang";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import { CoupleModel } from "../../../../models/CoupleModel";
import { MedicalRecordEvolutionType, MedicalRecordType } from "../../../../types/MedicalRecordType";
import { MedicalRecordModel } from "../../../../models/MedicalRecordModel";
import { DefaultUser } from "../../../../providers/DefaultData";
import { BtnBold, BtnBulletList, BtnItalic, BtnNumberedList, BtnStrikeThrough, BtnUnderline, BtnUndo, Editor, EditorProvider, Separator, Toolbar } from "react-simple-wysiwyg";
import { TreatmentMedicationType, TreatmentType } from "../../../../types/TreatmentType";
import { TreatmentModel } from "../../../../models/TreatmentModel";
import CustomSelect from "../../../../components/form/CustomSelect";
import moment from "moment";
import TreatmentMedications from "./TreatmentMedications";
import { FileUploadProvider, FileUploadProviderContext } from "../../../../providers/FileUploadProvider";
import PDFModal from "../../../../components/PDFModal";

interface Props {
    show: boolean
    onHide: () => void
    onSave: (treatment: TreatmentType) => void
    treatment: TreatmentType | null
    medical_record: MedicalRecordType
}

const PrescriptionModal: FC<Props> = ({ show, onHide, onSave, treatment, medical_record }) => {
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const intl = useIntl();
    const DefaultErrorMsg = intl.formatMessage(LANG_ERROR);
    const [errorMsg, setErrorMsg] = useState(DefaultErrorMsg);
    const [medications, setMedications] = useState<TreatmentMedicationType[]>((treatment?.treatment_medications) ? treatment.treatment_medications : []);
    const [medicationsTrigger, setMedicationsTrigger] = useState<TreatmentMedicationType[]>((treatment?.trigger_medications) ? treatment.trigger_medications : []);
    const [printUrl, setPrintUrl] = useState("");
    const [showPrint, setShowPrint] = useState(false);

    return (
        <>
            {printUrl && showPrint && (
                <PDFModal
                    url={printUrl}
                    show={true}
                    onHide={() => setShowPrint(false)}
                />
            )}
            <Modal
                show={show}
                onHide={() => {
                    onHide();
                }}
                className="modal-default"
                size="xl"
                backdrop="static"

            >
                <Form noValidate onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <Modal.Header closeButton closeVariant="white">
                        <Modal.Title>
                            Receita
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {hasError && (<Alert variant={'danger'}>{errorMsg}</Alert>)}
                        <TreatmentMedications
                            medications={medications}
                            treatment={treatment}
                            type="TREAT"
                            onChange={(med) => {
                                setMedications(med);
                            }}
                            prescription={true}
                        />
                        <TreatmentMedications
                            medications={medicationsTrigger}
                            treatment={treatment}
                            type="TRIGG"
                            onChange={(med) => {
                                setMedicationsTrigger(med);
                            }}
                            prescription={true}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-light" onClick={onHide} className="btn-custom btn-custom-light">
                            <FormattedMessage {...LANG_COMMON_CANCEL} />
                        </Button>
                        <FileUploadProvider url={undefined} onChange={() => { }} onDone={() => { }}>
                            <FileUploadProviderContext.Consumer>
                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, print, uploadErrorMessage }) => (
                                    <Button variant="primary" type="button" disabled={loading} className="btn-custom bg-custom-gradient"
                                        onClick={(e) => {
                                            setLoading(true);
                                            setShowPrint(false);
                                            setPrintUrl("");

                                            print(`/api/treatment/${treatment?.id}/print-prescription`,
                                                { 'medications': [...medications, ...medicationsTrigger].filter((m) => m.print).map((m) => m.id).join(",") })
                                                .then((resp) => {
                                                    setLoading(false);
                                                    if (typeof resp === 'string') {
                                                        setPrintUrl(resp);
                                                        setShowPrint(true);
                                                    }
                                                });
                                        }}
                                    >
                                        {loading ? <><ClipLoader color={'#ffffff'} loading={loading} size={'12px'} /> <FormattedMessage {...LANG_COMMON_LOADING} /></> : <><i className="bi bi-printer-fill"></i> Imprimir</>}
                                    </Button>
                                )}
                            </FileUploadProviderContext.Consumer>
                        </FileUploadProvider>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default PrescriptionModal;